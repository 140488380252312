import React from 'react'

const Content = () => {

    document.title = "E3 System"

    return (
        <div className="container mt-3">
            <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <img className="w-100" src="./assets/images/home/botonesrojos.png" alt="Botones" />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <img className="w-100" src="./assets/images/home/reparacion.png" alt="Reparacion" />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <img className="w-100" src="./assets/images/home/precios.png" alt="Precios" />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <img className="w-100" src="./assets/images/home/tecnicos.png" alt="Tecnicos" />
                </div>
            </div>
        </div>
    )
}

export default Content