import React from 'react'
import '../../styles/home.css';


import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Whatsapp = () => {
    return (
        <a 
            href="https://api.whatsapp.com/send?phone=573016590843&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Servicio%20Tecnico." 
            className="whatsapp-icon" 
            target="_blank"
            rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
        </a>
    )
}

export default Whatsapp