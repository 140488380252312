import React from 'react'

const Footer = () => {
    return (
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
            <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12"></div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className=""><small>CARRERA 8 # 61 - 132</small></div>
                    <div className=""><small>BUCARAMANGA COLOMBIA</small></div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div><small>TELEFONOS:</small></div>
                    <div><small>(+57) 301 659 08 43</small></div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="text-danger align-middle">
                        <small>info@e3system.com.co</small>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12"></div>
            </div>
        </footer>
    )
}

export default Footer