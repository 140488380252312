import React from 'react'
import { Link } from "react-router-dom"

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-ligh">
          <Link to="/" className="navbar-brand">
            <img className="" src="./assets/images/logo.png" alt="e3system logo" />
          </Link>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="navbar-nav mr-auto mt-2 mt-lg-0">
                <Link to="/" className="nav-item nav-link active">Inicio</Link>
                <Link to="/" className="nav-item nav-link disabled">Equipos</Link>
                <Link to="/" className="nav-item nav-link disabled">Repuestos</Link>
                <Link to="/" className="nav-item nav-link disabled">Galeria</Link>
                <Link to="/contact" className="nav-item nav-link">Contacto</Link>
            </div>
          </div>
        </nav>
    )
}

export default Navbar
