import React from 'react'
import Carousel from './home/Carousel'
import Content from './home/Content'
import Footer from './home/Footer'
import Whatsapp from './home/Whatsapp'

const Home = () => {
    return (
        <div>
            <Carousel />
            <Content />
            <Footer />
            <Whatsapp />
        </div>
    )
}

export default Home
